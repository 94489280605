import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import './signupStyles.css'; // Assuming signupStyles.css already exists and contains the styles for the signup flow.

const NewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = React.useRef(null);

    const { emailOrPhone, xToken } = location.state || {};
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const validatePassword = (password) => {
        const minLength = 10;
        const maxLength = 32;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const noSpaces = !/\s/.test(password);
        const validLength = password.length >= minLength && password.length <= maxLength;

        if (!validLength) {
            return `Password must be between ${minLength} and ${maxLength} characters long.`;
        }
        if (!hasUppercase) {
            return "Password must contain at least one uppercase letter.";
        }
        if (!hasLowercase) {
            return "Password must contain at least one lowercase letter.";
        }
        if (!hasNumber) {
            return "Password must contain at least one number.";
        }
        if (!noSpaces) {
            return "Password must not contain any spaces.";
        }

        return null;
    };

    const handleConfirmClick = async () => {
        const validationError = validatePassword(password);

        if (validationError) {
            toast.current.show({ severity: 'error', summary: 'Invalid Password', detail: validationError, life: 3000 });
            return;
        }

        setLoading(true);
        try {
            const response = await fetch('https://backend.bitxuae.com/auth/reset-password', {
                // const response = await fetch('http://ec2-54-161-54-218.compute-1.amazonaws.com:3000/auth/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': xToken, // Send the x-token in headers
                },
                body: JSON.stringify({ email: emailOrPhone, password }),
            });

            const data = await response.json();
            if (response.ok) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                navigate('/login');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="password-container">
            <Toast ref={toast} />

            {/* Back Arrow */}
            <div className="back-arrow">
                <button onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left"></i>
                </button>
            </div>

            {/* Title */}
            <h2>Create Your New Password</h2>

            {/* Enter Password */}
            <div className="form-group">
                <label htmlFor="password">Enter Password</label>
                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    id="password"
                    className="form-control"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <i
                    className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                    onClick={togglePasswordVisibility}
                ></i>
            </div>

            {/* Password Requirements */}
            <div className="password-requirements">
                <ul>
                    <li>10-32 characters.</li>
                    <li>At least one uppercase, lowercase, and number.</li>
                    <li>Does not contain any spaces.</li>
                </ul>
            </div>

            {/* Confirm Button */}
            <button
                type="button"
                className="btn btn-confirm"
                onClick={handleConfirmClick}
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Confirm'}
            </button>
        </div>
    );
};

export default NewPassword;
