import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { apisService } from '../../services/apiService'; // Assuming apisService is defined

import siteLogo from '../../assets/images/logo.png';
import './signupStyles.css'; // Use the existing styles for consistency

const ForgotPassword = () => {
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [loading, setLoading] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const isValidEmail = (email) => {
        return emailRegex.test(email);
    };

    const handleForgotPassword = async () => {
        if (!emailOrPhone) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter your email or phone number.', life: 3000 });
            return;
        }

        if (!isValidEmail(emailOrPhone)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter a valid email address.', life: 3000 });
            return;
        }

        setLoading(true);
        const payload = { email: emailOrPhone };

        try {
            // Call the API using apisService
            const response = await apisService.forgetPasswordApi(payload);

            if (response?.message === 'Reset password verification code sent to your email.') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                navigate('/verifyEmail', { state: { emailOrPhone } }); // Navigate with email/phone info
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error?.message || 'Failed to send reset password code.',
                life: 5000,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="forgot-password-container signup-main">
            <Toast ref={toast} />

            {/* Title */}
            <h2 className="signup-title">Forgot Password</h2>

            {/* Input for Email/Phone */}
            <div className="form-group">
                <label htmlFor="emailPhone">Enter Email/Phone number</label>
                <input
                    type="text"
                    className="form-control"
                    id="emailPhone"
                    placeholder="Email/Phone"
                    value={emailOrPhone}
                    onChange={(e) => setEmailOrPhone(e.target.value)}
                />
            </div>

            {/* Next Button */}
            <button
                type="button"
                className="btn btn-login"
                onClick={handleForgotPassword}
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Next'}
            </button>
        </div>
    );
};

export default ForgotPassword;
