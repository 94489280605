import api from '../api/apiManager';

class ApisService {

    constructor() {
    }
    //   reports api

    signupApi = async (email) => {
        const response = await api.post(`/auth/signup`, email)
        return response

    };

    reSendCodeApi = (email, type) => {
        const response = api.get(`/auth/resend-code?email=${email}&type=${type}`)
        return response

    };
    verifyCodeApi = (payload) => {
        const response = api.post(`/auth/verify-code`, payload)
        return response

    };
    verifyAccountApi = (payload) => {
        const response = api.post(`/auth/verify-account`, payload)
        return response

    };
    getKycUser = () => {
        const response = api.get(`/kyc/user`)
        return response

    };
    setPasswordApi = async (payload, xToken) => {
        // console.log("payload in password", payload)
        // const pay = JSON.stringify(payload)
        // const response = api.post(`/auth/set-password`, pay)
        // // const response = api.post(`/auth/set-password`, payload)
        // console.log(response, "response paaaaas")
        // return response
        try {
            console.log("token is ", xToken)
            console.log("Payload in setPasswordApi:", payload);
            const response = await api.post(
                `/auth/set-password`,
                payload, // Pass the payload as is
                {
                    headers: {
                        "Content-Type": "application/json",
                        'x-token': xToken, // Add the x-token header
                    },
                }
            );
            console.log("Response in setPasswordApi:", response);
            return response;
        } catch (error) {
            console.error("Error in setPasswordApi:", error);
            throw error;
        }
    };
    loginApi = async (payload) => {
        console.log("payload", payload)
        const response = await api.post(`/auth/login`, payload)
        console.log("login", response)
        return response

    };
    forgetPasswordApi = (payload) => {
        const response = api.post(`/auth/forgot-password`, payload)
        return response

    };
    getNewsList = () => {
        const response = api.get(`/latest-news/list`)
        return response

    };
    getAnnouncementList = () => {
        const response = api.get(`/announcement/list`)
        return response

    };
    getUserProfile = () => {
        const response = api.get(`/user/profile`)
        return response

    };

}
var apisService = new ApisService();
export { apisService };

