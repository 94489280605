import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
// import './KycTwoAuth.css';
import './signupStyles.css';

const KycTwoAuth = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [selfieImage, setSelfieImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isCameraOpen, setIsCameraOpen] = useState(false);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const { country: selectedCountry, idType: selectedIdType } = location.state || {};

    useEffect(() => {
        if (!selectedCountry || !selectedIdType) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Missing required data.', life: 3000 });
            navigate(-1);
        }
    }, [selectedCountry, selectedIdType, navigate]);

    const handleFileUpload = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const openCamera = () => {
        setIsCameraOpen(true);
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Unable to access camera.', life: 3000 });
                console.error('Error accessing camera:', error);
            });
    };

    const captureSelfie = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
            const selfieFile = new File([blob], 'selfie.jpg', { type: 'image/jpeg' });
            setSelfieImage(selfieFile);
        });

        closeCamera();
    };

    const closeCamera = () => {
        setIsCameraOpen(false);
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
    };

    const removeSelfie = () => {
        setSelfieImage(null);
    };

    const handleContinue = async () => {
        if (!firstName || !lastName || !frontImage || !backImage || !selfieImage) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill all fields and upload all documents.', life: 3000 });
            return;
        }

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('countryShortName', selectedCountry);
        formData.append('type', selectedIdType);
        formData.append('frontPhoto', frontImage);
        formData.append('backPhoto', backImage);
        formData.append('selfie', selfieImage);

        setLoading(true);

        try {
            const token = localStorage.getItem('ACCESS_TOKEN');
            const response = await axios.post('https://backend.bitxuae.com/kyc', formData, {
                // const response = await axios.post('http://ec2-54-161-54-218.compute-1.amazonaws.com:3000/kyc', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                navigate('/login');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message || 'Something went wrong.', life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message || 'Failed to submit KYC.', life: 3000 });
            console.error('Error submitting KYC:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="verification-container">
            <Toast ref={toast} />
            <div className="back-arrow">
                <a onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left"></i>
                </a>
            </div>
            <div className="header">
                <img src="/assets/logo.jpg" alt="Bitx Logo" className="img-responsive" />
            </div>
            <h2>Identity Verification</h2>
            <form>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <h3 className="mid-txt">Capture your document clearly</h3>
                <div className="document-upload">
                    <p>Front side of your ID Card</p>
                    <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, setFrontImage)} />
                </div>
                <div className="document-upload">
                    <p>Back side of your ID Card</p>
                    <input type="file" accept="image/*" onChange={(e) => handleFileUpload(e, setBackImage)} />
                </div>
                <div className="document-upload">
                    <p>Take a Selfie</p>
                    {selfieImage ? (
                        <div className="image-preview">
                            <img src={URL.createObjectURL(selfieImage)} alt="Selfie Preview" />
                            <button type="button" className="btn btn-danger" onClick={removeSelfie}>
                                Retake Selfie
                            </button>
                        </div>
                    ) : (
                        <button type="button" className="btn btn-camera" onClick={openCamera}>
                            Open Camera
                        </button>
                    )}
                </div>
                <button
                    type="button"
                    className="btn btn-custom"
                    onClick={handleContinue}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Continue'}
                </button>
            </form>

            {isCameraOpen && (
                <div className="camera-container">
                    <video ref={videoRef} autoPlay playsInline className="camera-video"></video>
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                    <button className="btn btn-capture" onClick={captureSelfie}>
                        Capture
                    </button>
                    <button className="btn btn-close" onClick={closeCamera}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default KycTwoAuth;
