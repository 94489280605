import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { ListBox } from "primereact/listbox";
import axios from "axios";
import './navBar.css';
import { Link, useNavigate } from "react-router-dom";

const TopNavBar = () => {
  const [searchedCoin, setSearchedCoin] = useState("");
  const [apiData, setApiData] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const navigate = useNavigate(); // Use for navigation

  useEffect(() => {
    if (searchedCoin) {
      getDailyStats(searchedCoin);
    } else {
      setIsCardVisible(false); // Hide the card when input is cleared
    }
  }, [searchedCoin]);

  const getDailyStats = async (searchedCoin) => {
    try {
      let token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(
        `https://backend.bitxuae.com/binance/daily-stats?search=${searchedCoin}`,
        // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?search=${searchedCoin}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data.slice(0, 10)); // Limit results to top 10
      setIsCardVisible(true); // Show the card when data is fetched
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  const handleItemClick = (item) => {

    const a = window.location.hash
    setIsCardVisible(false);
    setSearchedCoin("");
    navigate(`/crypto`, { state: { symbol: item.symbol } }); // Navigate to the details screen with symbol
  };


  const renderListItem = (item) => (
    <div className="flex">
      <div onClick={() => handleItemClick(item)} className="p-mr-2">
        {item.symbol}
      </div>

    </div>
  );

  return (
    <div className="header">
      <div className="row">
        <div className="col-sm-5 menu-desktop">
          <ul className="menu-items">
            <li>
              {/* <a href="#">Buy Crypto</a> */}
              <Link to="/buySell" className="nav-link">
                Buy Crypto
              </Link>
            </li>
            <li>
              <Link to="/marketing" className="nav-link">
                Markets
              </Link>
            </li>
            <li>
              <a href="#">Trade</a>
            </li>
            <li>
              <a href="#">Earn</a>
            </li>
            <li>
              <a href="#">Solana Meme Coins</a>
            </li>
          </ul>
        </div>
        <div className="col-sm-2" style={{ position: "relative" }}>
          <span className="search-header">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              value={searchedCoin}
              onChange={(e) => setSearchedCoin(e.target.value)} // Update input value state
              type="text"
              placeholder="Search"
              style={{ width: "100%" }}
            />
          </span>
          {isCardVisible && (
            <Card
              style={{
                position: "absolute",
                top: "50px",
                left: "0",
                width: "100%",
                zIndex: 10,
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {apiData.length > 0 ? (
                <ListBox
                  value={null}
                  options={apiData}
                  optionLabel="symbol"
                  itemTemplate={renderListItem}
                  style={{ width: "100%" }}
                />
              ) : (
                <p>No results found</p>
              )}
            </Card>
          )}
        </div>
        <div className="col-sm-5">
          <ul>
            <li>
              <button className="add-funds">
                <i className="fa-solid fa-right-from-bracket"></i>Add Funds
              </button>
            </li>
            <li>
              <i className="fa-solid fa-circle-user"></i>
            </li>
            <li>
              <i className="fa-solid fa-folder"></i>
            </li>
            <li>
              <i className="fa-solid fa-bell"></i>
            </li>
            <li>
              <i className="fa-solid fa-arrow-down-long"></i>
            </li>
            <li>
              <i className="fa-solid fa-sun"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
