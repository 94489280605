import React from "react";

const Trade = () => {

    return (
        <div>
            this is Trade screen
        </div>
    )
}
export default Trade