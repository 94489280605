import React from "react";
import './Referral.css'; // You will place your CSS in this file


const Refferal = () => {

    return (
        <div>
           <div class="container-fluid">
    <div class="row">

        {/* <!-- Content --> */}
        <div class="content-wrapper col-sm-12 col-md-12">
			<div class="Refer-main-cont">
				<div class="refer-section-bg">
    <div class="container1">
        <div class="refer-section">
            <div class="refer-text">
                <h2>Refer Friends.</h2>
                <p>Get 100 USD Equivalent Trading Fee Credit Each.</p>
            </div>
            <div class="refer-box">
                <div class="input-group" style={{display: 'flex', alignItems: 'center'}}>
                    <label>Referral ID</label>
<input type="text" class="form-control" value="CPA_005O9IKX6V" aria-label="Referral ID" readonly=""/>
                    <span class="input-group-text" id="basic-addon2" style={{padding: '6', borderRadius: '2'}}><i class="fa fa-copy"></i></span>
                </div>
                <div class="input-group" style={{display: 'flex', alignItems: 'center'}}>
                    <label>Referral Link</label>
<input type="text" class="form-control" placeholder="refere" value="https://www.example.com/referral" aria-label="Referral Link" readonly=""/>
                    <span class="input-group-text" id="basic-addon2" style={{padding: '6', borderRadius: '2'}}><i class="fa fa-copy"></i></span>
                </div>
                <button class="btn btn-invite">Invite Friends</button>
            </div>
        </div>
    </div>
</div>
<div class="container1 how-it-works">
    <h2>How it Works</h2>
    <div class="steps row justify-content-center">
        <div class="col-md-4 step">
            <h3>Step 1</h3>
            <p>Share your referral link with friends</p>
        </div>
        <div class="col-md-4 step">
            <h3>Step 2</h3>
            <p>Invite friends to sign up and accumulatively deposit more than $50</p>
        </div>
        <div class="col-md-4 step">
            <h3>Step 3</h3>
            <p>Receive 100 USD cashback voucher each</p>
        </div>
    </div>
</div>
<div class="container1 rules">
    <h3>Rules</h3>
    <p>Share your Referral ID / link with a friend who does not have a Bitx account.</p>
    <p>Regular Task: Referees must accumulatively deposit more than $50 within 14 days of registration. Both referrer and referee will be rewarded with a 100 USD trading fee rebate voucher each.</p>
</div>
<div class="container1 overview-box">
    <h4>Overview</h4>
    <div class="overview-stats row">
        <div class="col-md-4">
            <p>Trading Fee Rebate Voucher (USD)</p>
            <h5>0</h5>
        </div>
        <div class="col-md-4">
            <p>Total Referrals</p>
            <h5>0</h5>
        </div>
        <div class="col-md-4">
            <p>Successful Referrals</p>
            <h5>0</h5>
        </div>
    </div>
</div>
<div class="container1 my-referrals-box">
    <h4>My Referrals   <span class="date-range">2024-07-15 ➔ 2024-09-13 <i class="fa fa-calendar" aria-hidden="true"></i>
</span></h4> 
<div class="table-responsive">
    <table class="table table-borderless">
        <thead>
            <tr>
                <th style={{borderBottom: 'none'}}>ID</th>
                <th style={{borderBottom: 'none'}}>Registered</th>
                <th style={{borderBottom: 'none'}}>Task Completed</th>
            </tr>
        </thead>
        <tbody>
            <tr>    
                <td style={{borderTop: 'none'}}>Registered</td>
                <td style={{borderTop: 'none'}}>Task Completed</td>
                <td style={{borderTop: 'none'}}>
                    <span class="date-range">2024-07-15 ➔ 2024-09-13 <i class="fa fa-calendar" aria-hidden="true"></i>
</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>
			</div>
        </div>
    </div>
</div>
        </div>
    )
}
export default Refferal