import React from "react";

const IdentityVerification = () => {

    return (
        <div>
            this is Identity Verification screen
        </div>
    )
}
export default IdentityVerification