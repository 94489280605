import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faWallet, faUser, faClipboard, faCog, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import './sidebar.css';
import { Sidebar } from 'primereact/sidebar';
import logo from "../assets/images/logo.png"
import { Button } from "primereact/button";

const SidebarComponent = (props) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    // localStorage.removeItem('SAVED_EMAIL');
    // localStorage.removeItem('SAVED_PASSWORD');
    // localStorage.removeItem('REMEMBER_ME');
    props?.logout()
    // Navigate to login page
    navigate('/login');
  }
  console.log("side bar", visible);
  return (
    <>

      {visible ? <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
        <div className="sidebar bg-light d-flex flex-column p-2">
          {/* <Button icon="pi pi-arrow-cross" onClick={() => setVisible(false)} /> */}

          <div className="logo mb-3">
            <img className="logo" src={logo} />
          </div>
          <ul className="nav flex-column">
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/" className="nav-link">
                <FontAwesomeIcon icon={faHome} className="me-2" /> Dashboard
              </Link>
            </li>
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/assets" className="nav-link">
                <FontAwesomeIcon icon={faWallet} className="me-2" /> Assets
              </Link>
            </li>
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/orders" className="nav-link">
                <FontAwesomeIcon icon={faClipboard} className="me-2" /> Orders
              </Link>
            </li>
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/identity" className="nav-link">
                <FontAwesomeIcon icon={faUser} className="me-2" /> Identity Verification
              </Link>
            </li>

            {/* <li className="nav-item mb-3">
          <Link to="/crypto" className="nav-link">
            <FontAwesomeIcon icon={faUser} className="me-2" /> Graph
          </Link>
        </li> */}
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/referral" className="nav-link">
                <FontAwesomeIcon icon={faSyncAlt} className="me-2" /> Referral
              </Link>
            </li>
            <li onClick={() => setVisible(false)} className="nav-item mb-3">
              <Link to="/settings" className="nav-link">
                <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
              </Link>
            </li>
          </ul>
          <ul className="menu-items d-sm-none">
            <li onClick={() => setVisible(false)}>
              <a href="#">Buy Crypto</a>
            </li>
            <li onClick={() => setVisible(false)}>
              {/* <a href="#">Markets</a> */}
              <Link to="/marketing">Markets</Link>
            </li>
            <li onClick={() => setVisible(false)}>
              <a href="#">Trade</a>
            </li>
            <li onClick={() => setVisible(false)}>
              <a href="#">Earn</a>
            </li>
            <li onClick={() => setVisible(false)}>
              <a href="#">Solana Meme Coins</a>
            </li>
          </ul>
          <div>
            <button id="logoutBtn" onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </Sidebar> :
        <span className="sidebar-arrow" onClick={() => setVisible(true)} ><i className="fa-solid fa-bars"></i></span>
      }
    </>
  );
};

export default SidebarComponent;
