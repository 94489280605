import React from "react";

const Transactions = () => {

    return (
        <div>
            this is transactions screen
        </div>
    )
}
export default Transactions