import React from "react";
import './setting.css';
const SettingsScreen = () => {

  return (
    <div>
      <div class="container">
        <div class="row">
          {/* <!-- Content --> */}
          <div class="content-wrapper col-sm-12 col-md-12">
            <div class="edit-profile">
              {/* <!-- Profile Section --> */}
              <div class="panel-custom">
                <div class="panel-title">Profile</div>
                <div class="panel-body">
                  <div class="form-group row">
                    <div className="col-sm-6"><span>Nickname &amp; Avatar</span>
                      <p style={{ color: 'rgb(179, 177, 177)' }}>Setup an avatar and nickname, it is suggested not to use your real name</p>
                    </div>

                    <div class="account-name col-sm-6 text-sm-end">
                      {/* <img src="profile-pic.jpg" alt="user" style="width: 35px;margin-right: 11px;"> */}
                      <span style={{ paddingRight: '10px' }}>Ross Adams</span>
                      <button class="btn btn-default btn-custom">Edit</button>
                    </div>

                  </div>
                  <div class="form-group">
                    <div><span>Email</span>
                      <p style={{ color: 'rgb(179, 177, 177)' }}>Change your Email Address</p></div>
                    <div>
                      <span style={{ paddingRight: '10px' }}>rossadams@gmail.com</span>
                      <button class="btn btn-default btn-custom">Edit</button>
                    </div>

                  </div>
                  <div class="form-group">
                    <div>
                      <div><span>Login Password</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Change Your Password</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}>********</span>
                      <button class="btn btn-default btn-custom">Change</button>
                    </div>

                  </div>
                </div>
              </div>

              {/* <!-- Two-Factor Authentication Section --> */}
              <div class="panel-custom">
                <div class="panel-title">Two-Factor Authentication (2FA)</div>
                <div class="panel-body">
                  <div class="form-group">
                    <div>
                      <div><span>Passkeys (Biometrics)</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Protect your account and withdrawls with passkeys and/or scurity keys</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}></span>
                      <button class="btn btn-default btn-custom">Enable</button>
                    </div>

                  </div>
                  <div class="form-group">
                    <div>
                      <div><span>Authenticator App</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Use Authenticator App to Protect Your Accounts</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}>rossadams@gmail.com</span>
                      <button class="btn btn-default btn-custom">Enable</button>
                    </div>

                  </div>
                  <div class="form-group">
                    <div>
                      <div><span>Phone Number</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Use your phone number to protect your account</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}>********</span>
                      <button class="btn btn-default btn-custom">Manage</button>
                    </div>

                  </div>
                </div>
              </div>

              {/* <!-- Account Management Section --> */}
              <div class="panel-custom">
                <div class="panel-title">Account Management</div>
                <div class="panel-body">
                  <div class="form-group">
                    <div>
                      <div><span>Delete Account</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Once the account is disabled, most of your accounts will be restricted</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}></span>
                      <button class="btn btn-default btn-custom">Disable</button>
                    </div>

                  </div>
                  <div class="form-group">
                    <div>
                      <div><span>Delete Account</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Please note that account deletion is irreversable</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}></span>
                      <button class="btn btn-default btn-custom">Disable</button>
                    </div>

                  </div>
                </div>
              </div>

              {/* <!-- Link Account Section --> */}
              <div class="panel-custom">
                <div class="panel-title">Link Account</div>
                <div class="panel-body">
                  <div class="form-group">
                    <div>
                      <div><span>Link X Account</span>
                        <p style={{ color: 'rgb(179, 177, 177)' }}>Link Your X Account to Bitx</p></div>
                    </div>
                    <div>
                      <span style={{ paddingRight: '10px' }}>Not Linked</span>
                      <button class="btn btn-default btn-custom">Link</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SettingsScreen