// src/pages/signupPages/Login.js

import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apisService } from "../../services/apiService";
import siteLogo from '../../assets/images/logo.png';
// import { GoogleLogin } from 'react-google-login';
import '../../pages/signupPages/signupStyles.css';

const LoginScreen = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = React.useRef(null);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
        loadCredentials();
    }, []);

    const loadCredentials = () => {
        const savedEmail = localStorage.getItem('SAVED_EMAIL');
        const savedPassword = localStorage.getItem('SAVED_PASSWORD');
        const savedRememberMe = localStorage.getItem('REMEMBER_ME');

        if (savedRememberMe === 'true') {
            setRememberMe(true);
            setEmail(savedEmail || '');
            setPassword(savedPassword || '');
        }
    };

    const handleLogin = async () => {

        if (!email || !password) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter both email and password', life: 3000 });
            return;
        }

        setLoading(true);
        const payload = { email, password };

        try {
            const response = await apisService.loginApi(payload);
            const { accessToken, refreshToken, message } = response;

            // Save tokens
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('REFRESH_TOKEN', refreshToken);
            const userName = localStorage.setItem('userName', response?.user?.userName);
            const userId = localStorage.setItem('userId', response?.user?.id);

            // Save credentials if Remember Me is checked
            if (rememberMe) {
                localStorage.setItem('SAVED_EMAIL', email);
                localStorage.setItem('SAVED_PASSWORD', password);
                localStorage.setItem('REMEMBER_ME', 'true');
            } else {
                localStorage.removeItem('SAVED_EMAIL');
                localStorage.removeItem('SAVED_PASSWORD');
                localStorage.setItem('REMEMBER_ME', 'false');
            }

            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
            props.onLogin();
            navigate('/dashboard'); // Redirect to the dashboard
        } catch (error) {
            console.error("Error during login:", error.response?.data || error.message);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.message || 'Login failed', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLoginSuccess = async (response) => {
        const { tokenId } = response;
        try {
            const res = await axios.post('http://your-api-url.com/auth/google-login', { tokenId });
            const { accessToken, refreshToken, message } = res.data;

            // Save tokens
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('REFRESH_TOKEN', refreshToken);

            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
            navigate('/dashboard'); // Redirect to the dashboard
        } catch (error) {
            console.error('Error during Google login:', error.response?.data || error.message);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.message || 'Google login failed', life: 3000 });
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google login error:', error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Google login failed', life: 3000 });
    };

    return (
        <div className="login-container">
            <Toast ref={toast} />

            <div className="header-logo">
                <img src={siteLogo} alt="Bitx Logo" />
            </div>

            <h2>
                Log in <i className="fa fa-qrcode" aria-hidden="true"></i>
            </h2>

            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <InputText
                        id="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <div style={{ position: 'relative' }}>
                        <InputText
                            id="password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            placeholder="Password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                            className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                            style={{ position: 'absolute', top: '50%', right: '10px', cursor: 'pointer' }}
                            onClick={togglePasswordVisibility}
                        />
                    </div>
                </div>

                <div className="checkbox">
                    <Checkbox
                        inputId="remember"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.checked)}
                    />
                    <label htmlFor="remember">Remember Me</label>
                    <Link to="/forgetPassword" className="forgot-password-link">Forgot Password?</Link>
                </div>

                <Button label="Login" className="btn-login" onClick={handleLogin} loading={loading} />

                {/* <p className="text-center">or</p> */}

                {/* <GoogleLogin
                    clientId="YOUR_GOOGLE_CLIENT_ID"
                    buttonText="Continue with Google"
                    onSuccess={handleGoogleLoginSuccess}
                    onFailure={handleGoogleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => ( */}
                {/* <Button
                    className="btn-social btn-google"
                // onClick={renderProps.onClick}
                // disabled={renderProps.disabled}
                >
                    <i className="fa fa-google"></i> Continue with Google
                </Button> */}


                {/* <Button className="btn-social btn-apple">
                    <i className="fa fa-apple"></i> Continue with Apple
                </Button> */}
            </form>

            <p className="text-center signup-link">
                Don't have an account? <Link to="/signup">Signup</Link>
            </p>
        </div>
    );
};

export default LoginScreen;
