import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { apisService } from '../../services/apiService';
import './signupStyles.css';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = React.useRef(null);
    const emailOrPhone = location.state?.emailOrPhone || '';
    const [otp, setOtp] = useState(new Array(6).fill('')); // OTP as an array
    const [loading, setLoading] = useState(false);
    const [currentText, setCurrentText] = useState(`Please enter the 6-digit verification code sent to ${emailOrPhone}. The code is valid for 30 minutes.`);
    const inputRefs = useRef([]);

    const handleOtpChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
        if (value) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input if available
            if (index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            if (otp[index] !== '') {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (e.key === 'ArrowRight' && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleNextClick = async () => {
        const enteredOtp = otp.join('');
        if (enteredOtp.length !== 6) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the 6-digit code.', life: 3000 });
            return;
        }

        setLoading(true);
        const payload = {
            email: emailOrPhone,
            code: Number(enteredOtp),
        };

        try {
            const response = await apisService.verifyCodeApi(payload);

            if (response?.message === 'Operation successful.') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                const xToken = response?.token;
                navigate('/newPassword', { state: { xToken, emailOrPhone } });
            }
        } catch (error) {
            console.error('Error verifying code:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message || 'Verification failed.', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setLoading(true);
        setOtp(new Array(6).fill(''));
        try {
            const response = await apisService.reSendCodeApi(emailOrPhone, 'forgotten_password');
            setLoading(false);
            setCurrentText('Verification code will be sent to your email address if your account exists.');
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Verification code resent successfully.', life: 3000 });
        } catch (error) {
            console.error('Error resending code:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to resend verification code.', life: 3000 });
            setLoading(false);
        }
    };

    return (
        <div className="verify-container signup-main">
            <Toast ref={toast} />

            <div className="back-arrow">
                <button onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left"></i>
                </button>
            </div>

            <h2>Verify your number</h2>
            <p>{currentText}</p>

            <form className="signup-form">
                <span className="verification-code">Verification Code</span>
                <div className="form-group">
                    {otp.map((value, index) => (
                        <input
                            key={index}
                            type="text"
                            className="verification-input"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            autoFocus={index === 0}
                        />
                    ))}
                </div>

                <button type="button" className="sendCode-btn" onClick={handleResendCode} disabled={loading}>
                    Resend Code
                </button>

                <button
                    type="button"
                    className="btn btn-login"
                    onClick={handleNextClick}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Next'}
                </button>

                <div className="resend-code">
                    <p>Didn't receive the verification code? <a onClick={handleResendCode}>Resend</a></p>
                </div>
            </form>
        </div>
    );
};

export default VerifyEmail;
