import React from "react";
import './MemeCoins.css'; // You will place your CSS in this file

const MemeCoinsTable = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                {/* Content */}
                <div className="content-wrapper col-sm-12 col-md-12">
                    <div className="meme-coins-cont mt-4">
                        {/* Search and Tabs Row */}
                        <div className="tabs-container">
                            <div className="btn-group-custom">
                                <button className="btn btn-primary">
                                    <span className="fa fa-clock-o"></span> Last 24 hours <span className="fa fa-angle-down"></span>
                                </button>

                                <button className="btn btn-outline-info">
                                    <span style={{ paddingRight: '6px' }} className="fa fa-fire"></span> Trending 
                                    <span className="fa fa-info" style={{ paddingLeft: '6px' }}></span>   
                                    <span className="badge">5M</span>
                                    <span className="badge">1H</span>
                                    <span className="badge">6H</span>
                                    <span className="badge">24H</span>
                                </button>
                            </div>

                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#">Top</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Gainer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">New Pairs</a>
                                </li>
                            </ul>

                            <div>
                                <input className="form-control" type="text" placeholder="Search" />
                            </div>
                            <span className="fa fa-filter" style={{ color: '#666', backgroundColor: '#D9D9D9', padding: '6px 10px', borderRadius: '6px', fontSize: '20px' }}></span>
                        </div>

                        {/* Table */}
                        <div style={{ height: '100vh', overflowY: 'scroll' }}>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Token</th>
                                            <th>Exchanges</th>
                                            <th>Price</th>
                                            <th>1h</th>
                                            <th>4h</th>
                                            <th>12h</th>
                                            <th>24h</th>
                                            <th>Volume</th>
                                            <th>Liq</th>
                                            <th>Txns</th>
                                            <th>Mkt Cap</th>
                                            <th>Buyers</th>
                                            <th>Sellers</th>
                                            <th>Created</th>
                                            <th>Last Txn</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Example Row */}
                                        {[
                                            {
                                                token: 'Neiro',
                                                price: '$0.00034',
                                                changes: ['4.90%', '14.45%', '7.52%', '796.75%'],
                                                volume: '$112M',
                                                liq: '$2.35M',
                                                txns: '21.1K',
                                                mktCap: '$143M',
                                                buyers: 4801,
                                                sellers: 4433,
                                                created: '2 mos',
                                                lastTxn: '42 secs'
                                            },
                                            // Add other tokens here
                                        ].map((row, index) => (
                                            <tr key={index}>
                                                <td><img src="table-icon.jpg" className="icon" alt="Token" /> {row.token}</td>
                                                <td><img src="https://icons.veryicon.com/png/o/miscellaneous/simple-icon/exchange-14.png" className="icon" alt="Exchange" /></td>
                                                <td>{row.price}</td>
                                                {row.changes.map((change, idx) => (
                                                    <td key={idx}>{change}</td>
                                                ))}
                                                <td>{row.volume}</td>
                                                <td>{row.liq}</td>
                                                <td>{row.txns}</td>
                                                <td>{row.mktCap}</td>
                                                <td><span className="badge badge-buy">{row.buyers}</span></td>
                                                <td><span className="badge badge-sell">{row.sellers}</span></td>
                                                <td>{row.created}</td>
                                                <td>{row.lastTxn}</td>
                                                <td><img src="https://cdn-icons-png.flaticon.com/512/8276/8276310.png" className="icon" alt="Action" /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemeCoinsTable;
