import React from "react";
import './assets.css'; 

const AssetsScreen = () => {

    return (
        <div>
            <div class="container-fluid">
    <div class="row">

        {/* <!-- Content --> */}
        <div class="content-wrapper container">
			<div class="panel panel-default desktop"  style={{ width: '100%'}}>
                <div class="panel-body"  style={{ display: 'flex', justifyContent:'space-between', width: '100%'}}>
                    <div style={{width: '100%'}}>
                        <h4>Estimated Balance</h4>
                        <p><strong>0.00 BTC</strong> <span class="text-muted">= $0.00</span></p>
                        <p>Total PNL: $0.00 (0.00%)</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent:'end', width: '100%', alignItems: 'start', gap: '20px'}}>
                        <a href="/"><button class="btn">Deposit</button></a>
                        <a href="/"><button class="btn">Withdraw</button></a>
                        <a href="/"><button class="btn">Transfer</button></a>
                    </div>
                </div>
            </div>
			<div class="my-assets-table">

            {/* <!-- Balance and Deposit Section --> */}
            <h2>My Assets</h2>
            <div class="card tab-content">
                <h4>Coin View</h4>
                <table class="table">
                    <thead>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                            <th  style={{ border: 'none'}}>Coin Price</th>
                            <th  style={{ border: 'none'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}} class="coin-name">
                                {/* <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" alt="BTC"> */}
                                BTC <small>Bitcoin</small>
                            </td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}><button class="btn btn-default">Cash in</button></td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}} class="coin-name">
                                {/* <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" alt="ETH"> */}
                                ETH <small>Ethereum</small>
                            </td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}><button class="btn btn-default">Cash in</button></td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}} class="coin-name">
                                {/* <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" alt="BNB"> */}
                                BNB <small>Binance</small>
                            </td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}><button class="btn btn-default">Cash in</button></td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}} class="coin-name">
                                {/* <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" alt="USDT"> */}
                                USDT <small>Tether</small>
                            </td>
                            <td style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}>57,970.90</td>
                            <td  style={{ border: 'none'}}><button class="btn btn-default">Cash in</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        
            {/* <!-- Recent Transactions --> */}
            <h2>Recent Transactions</h2>
            <div class="card tab-content">
                <div>
				<ul class="nav nav-tabs"  style={{ borderBottom: 'none', marginBottom: '20px'}}>
					<li class="nav-item active">
						<a class="nav-link " data-toggle="tab" href="#all">All</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#recent">Recent</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#Buy-Sell">Buy Sell</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#Deposit-Withdraw">Deposit & Withdraw</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#Transfer">Transfer</a>
					</li>
				</ul>
				</div>
				<div id="all" class="tab-pane active">
					<div class="table-responsive">
						<table class="table">
                    <thead  style={{ border: 'none'}}>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Date</th>
                            <th  style={{ border: 'none'}}>Time</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}}>Transfer</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Deposit</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Buy</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Sell</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                    </tbody>
                </table>
					</div>
				</div>
				<div id="recent" class="tab-pane">
				<div class="table-responsive">
						<table class="table">
                    <thead  style={{ border: 'none'}}>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Date</th>
                            <th  style={{ border: 'none'}}>Time</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}}>Transfer</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Deposit</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Buy</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Sell</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                    </tbody>
                </table>
					</div>
				</div>
				<div id="Buy-Sell" class="tab-pane">
				<div class="table-responsive">
						<table class="table">
                    <thead  style={{ border: 'none'}}>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Date</th>
                            <th  style={{ border: 'none'}}>Time</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}}>Transfer</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Deposit</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Buy</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Sell</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                    </tbody>
                </table>
					</div>
				</div>
				<div id="Deposit-Withdraw" class="tab-pane">
				<div class="table-responsive">
						<table class="table">
                    <thead  style={{ border: 'none'}}>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Date</th>
                            <th  style={{ border: 'none'}}>Time</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}}>Transfer</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Deposit</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Buy</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Sell</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                    </tbody>
                </table>
					</div></div>
				<div id="Transfer" class="tab-pane">
				<div class="table-responsive">
						<table class="table">
                    <thead  style={{ border: 'none'}}>
                        <tr>
                            <th  style={{ border: 'none'}}>Name</th>
                            <th  style={{ border: 'none'}}>Date</th>
                            <th  style={{ border: 'none'}}>Time</th>
                            <th  style={{ border: 'none'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{ border: 'none'}}>Transfer</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Deposit</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Buy</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount negative">-600</td>
                        </tr>
                        <tr>
                            <td  style={{ border: 'none'}}>Sell</td>
                            <td  style={{ border: 'none'}}>2024-09-11</td>
                            <td  style={{ border: 'none'}}>23:11:31</td>
                            <td  style={{ border: 'none'}} class="transaction-amount">+599.79123</td>
                        </tr>
                    </tbody>
                </table>
					</div>
				</div>
			 </div>
        </div>
        </div>
    </div>
</div>
        </div>
    )
}
export default AssetsScreen