import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import './signupStyles.css';

const KYCOne = () => {
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState('AT'); // Default to Austria
    const [selectedIdType, setSelectedIdType] = useState('ID Card');
    console.log("seleted country", selectedCountry)
    const handleCountryChange = (countryCode) => {
        setSelectedCountry(countryCode);
    };

    const handleIdTypeChange = (type) => {
        setSelectedIdType(type);
    };

    const handleContinueClick = () => {
        navigate('/kycAuthTwo', {
            state: {
                country: selectedCountry,
                idType: selectedIdType,
            },
        });
    };

    return (
        <div className="verification-box">
            <div className="header">
                <img src="logo.jpg" alt="Bitx Logo" className="img-responsive" />
            </div>

            <h3>Identity Verification</h3>

            <div className="form-group">
                <label>Select Issuing Country</label>
                <ReactFlagsSelect
                    selected={selectedCountry}
                    onSelect={handleCountryChange}
                    searchable
                    placeholder="Select Country"
                    className="dropdown-toggle"
                />
            </div>

            <div className="form-group">
                <label>Select ID Type</label>

                <div
                    className={`id-type-option ${selectedIdType === 'Passport' ? 'active' : ''}`}
                    onClick={() => handleIdTypeChange('Passport')}
                >
                    <i className="fa fa-id-card"></i>
                    <span>Passport</span>
                    <div className={`custom-radio ${selectedIdType === 'Passport' ? 'checked' : ''}`}></div>
                </div>

                <div
                    className={`id-type-option ${selectedIdType === 'ID Card' ? 'active' : ''}`}
                    onClick={() => handleIdTypeChange('ID Card')}
                >
                    <i className="fa fa-id-card"></i>
                    <span>ID Card <span className="recommended">Recommended</span></span>
                    <div className={`custom-radio ${selectedIdType === 'ID Card' ? 'checked' : ''}`}></div>
                </div>

                <div
                    className={`id-type-option ${selectedIdType === 'Driver License' ? 'active' : ''}`}
                    onClick={() => handleIdTypeChange('Driver License')}
                >
                    <i className="fa fa-id-card-o"></i>
                    <span>Driver License</span>
                    <div className={`custom-radio ${selectedIdType === 'Driver License' ? 'checked' : ''}`}></div>
                </div>
            </div>

            <p className="disclaimer">
                To understand how your personal information and biometric data are stored and used for verification, please read the User Identity Verification Statement and Jumio Privacy Policy.
            </p>

            <button className="btn-continue" onClick={handleContinueClick}>Continue</button>
        </div>
    );
};

export default KYCOne;
