import React from "react";
import './order.css'; 
const OrdersScreen = () => {

    return (
        <div>
           <div class="container-fluid">
    <div class="row">

        {/* <!-- Content --> */}
        <div class="content-wrapper col-sm-12 col-md-12">
			<div class="spot-order-cont">
                {/* <!-- Tabs for switching between orders --> */}
                <ul class="nav nav-tabs" id="orderTabs" role="tablist" style={{ height: '40px' }}>
                    <li class="active" role="presentation">
                        <a href="#spot-order" role="tab" data-toggle="tab">Spot Order</a>
                    </li>
                    <li role="presentation">
                        <a href="#binary-order" role="tab" data-toggle="tab">Binary Order</a>
                    </li>
                    <li role="presentation">
                        <a href="#order-history" role="tab" data-toggle="tab">Order History</a>
                    </li>
                </ul>
            
                {/* <!-- Tab Content --> */}
                <div class="tab-content" id="orderTabsContent">

                    {/* <!-- Spot Order Tab --> */}
                    <div class="tab-pane in active" id="spot-order" role="tabpanel">
                        {/* <!-- Filter Options --> */}
                        <div class="filter-options clearfix">
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="Filter">Filter</label>
                                <select id="Filter" class="form-control">
                                    <option>All</option>
                                    <option>Open Orders</option>
                                    <option>Closed Orders</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="Pair">Pair</label>
                                <select id="Pair" class="form-control">
                                    <option>All</option>
                                    <option>BTC/USDT</option>
                                    <option>ETH/USDT</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="Side">Side</label>
                                <select id="Side" class="form-control">
                                    <option>All</option>
                                    <option>Buy</option>
                                    <option>Sell</option>
                                </select>
                            </div>
                        </div>
            
                        {/* <!-- Table with Dummy Data --> */}
                        <div class="table-responsive order-table">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Pair</th>
                                        <th>Type</th>
                                        <th>Side</th>
                                        <th>Price</th>
                                        <th>Amount</th>
                                        <th>Total</th>
                                        <th>TP/SL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024-10-16</td>
                                        <td>BTC/USDT</td>
                                        <td>Limit</td>
                                        <td>Buy</td>
                                        <td>$27,000</td>
                                        <td>0.5</td>
                                        <td>$13,500</td>
                                        <td>None</td>
                                    </tr>
                                    <tr>
                                        <td>2024-10-12</td>
                                        <td>ETH/USDT</td>
                                        <td>Market</td>
                                        <td>Sell</td>
                                        <td>$1,600</td>
                                        <td>5</td>
                                        <td>$8,000</td>
                                        <td>None</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
            
                    {/* <!-- Binary Order Tab --> */}
                    <div class="tab-pane" id="binary-order" role="tabpanel">
                        {/* <!-- Filter Options --> */}
                        <div class="filter-options clearfix">
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="FilterBinary">Filter</label>
                                <select id="FilterBinary" class="form-control">
                                    <option>All</option>
                                    <option>Open Orders</option>
                                    <option>Closed Orders</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="PairBinary">Pair</label>
                                <select id="PairBinary" class="form-control">
                                    <option>All</option>
                                    <option>BTC/USDT</option>
                                    <option>ETH/USDT</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="SideBinary">Side</label>
                                <select id="SideBinary" class="form-control">
                                    <option>All</option>
                                    <option>Buy</option>
                                    <option>Sell</option>
                                </select>
                            </div>
                        </div>
            
                        {/* <!-- Table with Dummy Data --> */}
						<div class="table-responsive order-table">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Pair</th>
                                    <th>Type</th>
                                    <th>Side</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>TP/SL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2024-10-26</td>
                                    <td>BTC/USDT</td>
                                    <td>Limit</td>
                                    <td>Sell</td>
                                    <td>$28,000</td>
                                    <td>1.0</td>
                                    <td>$28,000</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>2024-10-06</td>
                                    <td>ETH/USDT</td>
                                    <td>Market</td>
                                    <td>Buy</td>
                                    <td>$1,600</td>
                                    <td>3</td>
                                    <td>$4,800</td>
                                    <td>None</td>
                                </tr>
                            </tbody>
                        </table>
						</div>
                    </div>
            
                    {/* <!-- Order History Tab --> */}
                    <div class="tab-pane" id="order-history" role="tabpanel">
                        {/* <!-- Filter Options --> */}
                        <div class="filter-options clearfix">
                            <div style={{ float: 'left', width:'20%'}}>
                                <label for="FilterHistory">Filter</label>
                                <select id="FilterHistory" class="form-control">
                                    <option>All</option>
                                    <option>Open Orders</option>
                                    <option>Closed Orders</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="PairHistory">Pair</label>
                                <select id="PairHistory" class="form-control">
                                    <option>All</option>
                                    <option>BTC/USDT</option>
                                    <option>ETH/USDT</option>
                                </select>
                            </div>
                            <div style={{ float: 'left',width:'20%'}}>
                                <label for="SideHistory">Side</label>
                                <select id="SideHistory" class="form-control">
                                    <option>All</option>
                                    <option>Buy</option>
                                    <option>Sell</option>
                                </select>
                            </div>
                        </div>
            
                        {/* <!-- Table with Dummy Data --> */}
						<div class="table-responsive order-table">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Pair</th>
                                    <th>Type</th>
                                    <th>Side</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>TP/SL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2024-09-25</td>
                                    <td>BTC/USDT</td>
                                    <td>Limit</td>
                                    <td>Buy</td>
                                    <td>$26,500</td>
                                    <td>0.8</td>
                                    <td>$21,200</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>2024-09-28</td>
                                    <td>ETH/USDT</td>
                                    <td>Market</td>
                                    <td>Sell</td>
                                    <td>$1,620</td>
                                    <td>4</td>
                                    <td>$6,480</td>
                                    <td>None</td>
                                </tr>
                            </tbody>
                        </table>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </div>
    )
}
export default OrdersScreen