import React from 'react';
import './OverView.css'; // You will place your CSS in this file

const OverView = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                {/* Content Wrapper */}
                <div className="content-wrapper container">
                    {/* Estimated Balance Panel */}
                    <div className="panel panel-default desktop" style={{ width: '100%' }}>
                        <div className="panel-body" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '100%' }}>
                                <h4>Estimated Balance</h4>
                                <p><strong>0.00 BTC</strong> <span className="text-muted">= $0.00</span></p>
                                <p>Total PNL: $0.00 (0.00%)</p>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start', gap: '20px' }}>
                                <a href="/"><button className="btn">Deposit</button></a>
                                <a href="/"><button className="btn">Withdraw</button></a>
                                <a href="/"><button className="btn">Transfer</button></a>
                            </div>
                        </div>
                    </div>

                    {/* My Assets Table */}
                    <div className="my-assets-table">
                        <h2>My Assets</h2>
                        <div className="card tab-content">
                            <h4>Coin View</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ border: 'none' }}>Name</th>
                                        <th style={{ border: 'none' }}>Amount</th>
                                        <th style={{ border: 'none' }}>Coin Price</th>
                                        <th style={{ border: 'none' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {['BTC', 'ETH', 'BNB', 'USDT'].map((coin, index) => (
                                        <tr key={index}>
                                            <td style={{ border: 'none' }} className="coin-name">
                                                <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${index + 1}.png`} alt={coin} />
                                                {coin} <small>{coin === 'BTC' ? 'Bitcoin' : coin === 'ETH' ? 'Ethereum' : coin === 'BNB' ? 'Binance' : 'Tether'}</small>
                                            </td>
                                            <td style={{ border: 'none' }}>57,970.90</td>
                                            <td style={{ border: 'none' }}>57,970.90</td>
                                            <td style={{ border: 'none' }}><button className="btn btn-default">Cash in</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Recent Transactions Section */}
                        <h2>Recent Transactions</h2>
                        <div className="card tab-content">
                            <div>
                                <ul className="nav nav-tabs" style={{ borderBottom: 'none', marginBottom: '20px' }}>
                                    {['All', 'Recent', 'Buy Sell', 'Deposit & Withdraw', 'Transfer'].map((tab, index) => (
                                        <li className={`nav-item ${index === 0 ? 'active' : ''}`} key={index}>
                                            <a className="nav-link" data-toggle="tab" href={`#${tab.replace(' ', '-')}`}>{tab}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Tab Content */}
                            {['all', 'recent', 'Buy-Sell', 'Deposit-Withdraw', 'Transfer'].map((tabId, index) => (
                                <div id={tabId} className={`tab-pane ${index === 0 ? 'active' : ''}`} key={index}>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead style={{ border: 'none' }}>
                                                <tr>
                                                    <th style={{ border: 'none' }}>Name</th>
                                                    <th style={{ border: 'none' }}>Date</th>
                                                    <th style={{ border: 'none' }}>Time</th>
                                                    <th style={{ border: 'none' }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {[
                                                    { name: 'Transfer', date: '2024-09-11', time: '23:11:31', amount: -600 },
                                                    { name: 'Deposit', date: '2024-09-11', time: '23:11:31', amount: 599.79123 },
                                                    { name: 'Buy', date: '2024-09-11', time: '23:11:31', amount: -600 },
                                                    { name: 'Sell', date: '2024-09-11', time: '23:11:31', amount: 599.79123 }
                                                ].map((transaction, tIndex) => (
                                                    <tr key={tIndex}>
                                                        <td style={{ border: 'none' }}>{transaction.name}</td>
                                                        <td style={{ border: 'none' }}>{transaction.date}</td>
                                                        <td style={{ border: 'none' }}>{transaction.time}</td>
                                                        <td style={{ border: 'none' }} className={`transaction-amount ${transaction.amount < 0 ? 'negative' : ''}`}>{transaction.amount < 0 ? `-${Math.abs(transaction.amount)}` : `+${transaction.amount}`}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverView;
