import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import "./buySell.css";
// import "./App.css"; // Include your CSS styles here

const BuySell = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [withdrawForm, setWithdrawForm] = useState({
        address: "",
        network: null,
        amount: "",
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const networks = [
        { label: "Bitcoin", value: "bitcoin" },
        { label: "Solana", value: "solana" },
    ];

    const handleWithdraw = () => {
        if (!withdrawForm.address || !withdrawForm.network || !withdrawForm.amount) {
            alert("Please fill out all fields.");
            return;
        }
        alert(`Withdrawal initiated for ${withdrawForm.amount} BTC.`);
    };

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    return (
        <div className="container">
            {/* Main Content */}
            <div className="row">
                <div className="col-md-12">
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Buy & Sell">
                            <h2 className="btm-title-bs">Buy Crypto Instantly</h2>
                            <div className="row">
                                {/* Hot Cryptos */}
                                <div className="col-md-6">
                                    <div className="crypto-list">
                                        <h5>Hot Cryptos</h5>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <span>
                                                    <img
                                                        src="https://cryptologos.cc/logos/binance-coin-bnb-logo.png"
                                                        alt="BNB"
                                                        width="25"
                                                    />
                                                    BNB
                                                </span>
                                                <span>$539.10</span>
                                                <span className="text-success">+1.83%</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span>
                                                    <img
                                                        src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                                                        alt="BTC"
                                                        width="25"
                                                    />
                                                    BTC
                                                </span>
                                                <span>$57,941.27</span>
                                                <span className="text-success">+0.96%</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Buy Form */}
                                <div className="col-md-6">
                                    <div className="crypto-form">
                                        <div className="tab-buttons">
                                            <Button label="Buy" className="active" />
                                            <Button label="Sell" />
                                        </div>
                                        <div className="crypto-form-content">
                                            <form>
                                                <div className="mb-4">
                                                    <label>Spend</label>
                                                    <div className="input-group">
                                                        <InputText placeholder="61 - 68,456" />
                                                        <Dropdown
                                                            options={[
                                                                { label: "AED", value: "aed" },
                                                                { label: "USD", value: "usd" },
                                                            ]}
                                                            placeholder="Currency"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label>Receive</label>
                                                    <div className="input-group">
                                                        <InputText placeholder="0" />
                                                        <Dropdown
                                                            options={[
                                                                { label: "USDT", value: "usdt" },
                                                                { label: "BTC", value: "btc" },
                                                            ]}
                                                            placeholder="Currency"
                                                        />
                                                    </div>
                                                </div>
                                                <Button label="Buy" className="w-100 btn-primary" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel header="Deposit">
                            <div className="deposit-form">
                                <h2>Deposit</h2>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <Dropdown
                                                options={[
                                                    { label: "AED United Arab Emirates", value: "aed" },
                                                ]}
                                                placeholder="Select Network"

                                            />
                                        </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <button onClick={openModal} className="btn-style" >Continue</button>
                                        </div>
                                    </div>
                                </div>
                        </TabPanel>

                        <TabPanel header="Withdraw">
                            <div className="withdraw-form">
                                <h2>Withdraw</h2>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>Withdraw To</label>
                                            <InputText
                                                placeholder="Enter Address"
                                                onChange={(e) =>
                                                    setWithdrawForm({ ...withdrawForm, address: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>Select Network</label>
                                            <Dropdown
                                                options={networks}
                                                onChange={(e) =>
                                                    setWithdrawForm({ ...withdrawForm, network: e.value })
                                                }
                                                placeholder="Select Network"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>Withdrawal Amount</label>
                                            <InputText
                                                placeholder="Enter Amount"
                                                onChange={(e) =>
                                                    setWithdrawForm({ ...withdrawForm, amount: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <button
                                            onClick={handleWithdraw}
                                            className="btn-style"
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                </div>



                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            {/* Modal */}
            <Dialog
                visible={isModalVisible}
                onHide={closeModal}
                header="Deposit BTC"
                style={{ width: "50vw" }}
            >
                <div>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/800px-QR_code_for_mobile_English_Wikipedia.svg.png"
                        alt="QR Code"
                        width="200"
                    />
                    <p>Network: Bitcoin</p>
                    <p>Address: 1qxy2kdgyjrstqqz2n0ryf24393b83kkjfx0vlih</p>
                </div>
            </Dialog>
        </div>
    );
};

export default BuySell;
